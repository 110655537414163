<template>
  <div>
    <FormularioPedido
      :titulo="`O pedido ${this.pedido.id ?? ''} foi enviado para a loja!`"
      :somente-leitura="true"
    />
    <Rodape />
  </div>
</template>

<script>
import FormularioPedido from "@/components/loja/FormularioPedido.vue";
import Rodape from "@/components/shared/Rodape.vue";

import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";

export default {
  name: "PedidoRealizado",
  props: {
    id: { type: String, required: true },
  },
  components: {
    FormularioPedido,
    Rodape,
  },
  setup() {
    const store = useStore();
    const loja = computed(() => store.state.loja);
    const pedido = computed(() => store.state.pedido);

    useHead({
      title: computed(
        () =>
          `${loja.value.nome} | Pedido enviado com sucesso | Loja MEI Grátis`
      ),
      meta: [
        {
          name: "robots",
          content: "noindex",
        },
      ],
    });

    return { pedido };
  },
  async beforeRouteEnter(origem, destino, proximo) {
    proximo((vm) => {
      if (vm.pedido.itens.length === 0) vm.$router.replace({ name: "loja" });
    });
  },
};
</script>
